import React, { useEffect, useState, useRef } from "react";
import "./Appuntamenti.css";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../Redux/store";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import {
  insurance,
  url_deleteAppointment,
  url_getAppointments,
  url_getResources,
  url_rescheduleAppointmet,
  url_searchAvailabilities,
} from "../lib/url";
import { Activity, GroupedActivity, Options } from "../lib/types";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { addLocale } from "primereact/api";
import { Nullable } from "primereact/ts-helpers";
import { setAppointment } from "../Redux/reducer/userSlice";

interface Appointment {
  id: string;
  activityId: string;
  date: string;
  startTime: string;
  endTime: string;
  resourceId: string;
  firstName: string;
  secondName: string;
  price: number;
  appointmentDate: string;
}

interface AppointmentProps {
  setActiveIndex: (index: number) => void;
  activities: Activity[];
  groupedActivities: GroupedActivity[];
  loading: boolean;
}

addLocale("it", {
  firstDayOfWeek: 1,
  dayNames: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
  dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
  dayNamesMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
  monthNames: [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ],
  monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
  today: "Oggi",
  clear: "Pulisci",
});

const Appuntamenti: React.FC<AppointmentProps> = ({ setActiveIndex, activities, groupedActivities, loading }) => {
  const [medici, setMedici] = useState<{ label: string; value: string }[]>([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [preparationDialogVisible, setPreparationDialogVisible] = useState(false);
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);
  const [preparationText, setPreparationText] = useState("");
  const [selectedAppointment, setSelectedAppointment] = useState<Appointment | null>(null);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [newDate, setNewDate] = useState<Date | null>(null);
  const [newStartTime, setNewStartTime] = useState<string>("");
  const [newEndTime, setNewEndTime] = useState<string>("");
  const [newAvailabilityId, setNewAvailabilityId] = useState<string>("");
  const [selectPrestazione, setSelectPrestazione] = useState<Activity | null>(null);
  const [selectMedico, setSelectMedico] = useState<string | null>(null);
  const [date, setDate] = useState<Nullable<Date>>(null);
  const [startTime, setStartTime] = useState<string | null>("00:01");
  const [endTime, setEndTime] = useState<string | null>("23:59");
  const [availabilities, setAvailabilities] = useState<any[]>([]);
  const today = new Date();
  const toast = useRef<Toast>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
  const user = useSelector((state: RootState) => state.user);
  const token = useSelector((state: RootState) => state.user.token);
  const groupedItemTemplate = (option: Options) => {
    return (
      <div className="flex align-items-center">
        <div>{option.label}</div>
      </div>
    );
  };
  const cardTitle = (activityId: string) => {
    const activity = activities.find(a => a.id === activityId);
    return activity ? activity.name : "Attività sconosciuta";
  };
  const resetSearch = () => {
    setSelectPrestazione(null);
    setSelectMedico(null);
    setDate(null);
    setStartTime("00:01");
    setEndTime("23:59");
    setAvailabilities([]);
  };
  const openDeleteDialog = (appointment: Appointment) => {
    setSelectedAppointment(appointment);
    setDeleteVisible(true);
  };

  const openDialog = (appointment: Appointment) => {
    setSelectedAppointment(appointment);
    const activity = activities.find(a => a.id === appointment.activityId);
    setSelectPrestazione(activity || null);
    setDialogVisible(true);
  };

  const openConfirmationDialog = (appointment: any) => {
    setSelectedAppointment(appointment);
    setConfirmationDialogVisible(true);
  };

  const confirmAppointment = () => {
    if (selectedAppointment) {
      handleReschedule(selectedAppointment);
    }
    setConfirmationDialogVisible(false);
    resetSearch();
  };
  const closeDialog = () => {
    setDialogVisible(false);
    resetSearch();
  };
  const closeConfirmationDialog = () => {
    setConfirmationDialogVisible(false);
    resetSearch();
  };
  const handleDelete = async () => {
    if (!selectedAppointment) return;

    try {
      const response = await fetch(url_deleteAppointment, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ id: selectedAppointment.id }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.result === "KO") {
        throw new Error(data.msg);
      }

      toast.current?.show({
        severity: "success",
        summary: "Appuntamento eliminato",
        detail: "L'appuntamento è stato eliminato con successo.",
        life: 3000,
      });

      setDeleteVisible(false);
      setSelectedAppointment(null);
      setAppointments(appointments.filter(app => app.id !== selectedAppointment.id));
    } catch (error) {
      if (error instanceof Error) {
        console.error("Fetch error:", error.message);
        toast.current?.show({
          severity: "error",
          summary: "Errore",
          detail: `Si è verificato un errore: ${error.message}`,
          life: 3000,
        });
      } else {
        console.error("Unexpected error:", error);
        toast.current?.show({
          severity: "error",
          summary: "Errore",
          detail: "Si è verificato un errore sconosciuto.",
          life: 3000,
        });
      }
    }
  };
  const footerContent = (
    <div>
      <Button
        label="Elimina"
        className="elimina-btn appuntamenti-btn"
        icon="pi pi-check"
        onClick={handleDelete}
        autoFocus
      />
    </div>
  );
  const handleReschedule = async (appointment: Appointment) => {
    const appointmentDate = newDate
      ? `${newDate.getFullYear()}-${(newDate.getMonth() + 1).toString().padStart(2, "0")}-${newDate
          .getDate()
          .toString()
          .padStart(2, "0")}`
      : appointment.date;
    const startTime = appointment.startTime;
    const endTime = appointment.endTime;
    const availabilityId = appointment.id;

    const rescheduleData = {
      app_lid: appointments[0].id,
      new_availability_lid: availabilityId,
      new_app_start_time: startTime,
      new_app_end_time: endTime,
      new_app_date: appointmentDate,
    };

    try {
      const response = await fetch(url_rescheduleAppointmet, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(rescheduleData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.result === "KO") {
        throw new Error(data.msg);
      }

      toast.current?.show({
        severity: "success",
        summary: "Appuntamento aggiornato",
        detail: "L'appuntamento è stato aggiornato con successo.",
        life: 3000,
      });

      setDialogVisible(false);
      setSelectedAppointment(null);
      setNewDate(null);
      setNewStartTime("");
      setNewEndTime("");
      setNewAvailabilityId("");
      fetchAppointments();
    } catch (error) {
      if (error instanceof Error) {
        console.error("Fetch error:", error.message);
        toast.current?.show({
          severity: "error",
          summary: "Errore",
          detail: `Si è verificato un errore: ${error.message}`,
          life: 3000,
        });
      } else {
        console.error("Unexpected error:", error);
        toast.current?.show({
          severity: "error",
          summary: "Errore",
          detail: "Si è verificato un errore sconosciuto.",
          life: 3000,
        });
      }
    }
  };

  const openPreparationDialog = (activityId: string) => {
    const activity = activities.find(a => a.id === activityId);
    if (activity && activity.ACTIVITY_PREPARATION) {
      setPreparationText(activity.ACTIVITY_PREPARATION);
      setPreparationDialogVisible(true);
    } else {
      toast.current?.show({
        severity: "warn",
        summary: "Nessuna preparazione",
        detail: "Non ci sono informazioni di preparazione per questa attività.",
        life: 3000,
      });
    }
  };

  const generateTimeOptions = (start: string, end: string, interval: number) => {
    const times = [];
    let currentTime = new Date(`1970-01-01T${start}:00`);
    const endTime = new Date(`1970-01-01T${end}:00`);

    while (currentTime <= endTime) {
      const hours = currentTime.getHours().toString().padStart(2, "0");
      const minutes = currentTime.getMinutes().toString().padStart(2, "0");
      times.push({ label: `${hours}:${minutes}`, value: `${hours}:${minutes}` });
      currentTime = new Date(currentTime.getTime() + interval * 60000);
    }

    return times;
  };

  const timeOptions = generateTimeOptions("07:00", "21:00", 15);

  const handleSubmit = async () => {
    if (!selectPrestazione) {
      toast.current?.show({
        severity: "error",
        summary: "Errore",
        detail: "Seleziona una prestazione.",
        life: 3000,
      });
      return;
    }

    const prestazioneId = selectPrestazione.id;

    const searchParams = new URLSearchParams({
      activity_lid: prestazioneId,
      ava_results_number: "100",
      resource_lid: selectMedico ? String(selectMedico) : "",
      ava_min_time: startTime ? `${startTime}:00` : "00:01:00",
      ava_max_time: endTime ? `${endTime}:00` : "23:59:00",
      ava_start_time: "00:01:00",
      ava_end_time: "23:59:00",
      insurance_lid: insurance,
    });

    const startDate = date ? date : today;
    const endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + 1);

    searchParams.append("ava_start_day", formatDate(startDate));
    searchParams.append("ava_end_day", formatDate(endDate));

    try {
      const response = await fetch(`${url_searchAvailabilities}?${searchParams.toString()}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.result === "KO") {
        throw new Error(data.msg);
      }

      if (data.msg === "No availability found.") {
        toast.current?.show({
          severity: "warn",
          summary: "Nessuna disponibilità",
          detail: "Non ci sono disponibilità per i criteri selezionati.",
          life: 3000,
        });
        setAvailabilities([]);
      } else {
        setAvailabilities(data.return);
        toast.current?.show({
          severity: "info",
          summary: "Successo",
          detail: "Disponibilità caricate con successo.",
          life: 3000,
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error("Fetch error:", error.message);
        toast.current?.show({
          severity: "error",
          summary: "Errore",
          detail: `Si è verificato un errore: ${error.message}`,
          life: 3000,
        });
      } else {
        console.error("Unexpected error:", error);
        toast.current?.show({
          severity: "error",
          summary: "Errore",
          detail: "Si è verificato un errore sconosciuto.",
          life: 3000,
        });
      }
    }
  };

  const formatDate = (date: Date): string => {
    const pad = (n: number) => (n < 10 ? `0${n}` : n);
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
  };

  const fetchAppointments = async () => {
    if (isLoggedIn && user.codiceFiscale) {
      try {
        const response = await fetch(`${url_getAppointments}${user.patientInfo.patientId}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data.result === "KO") {
          throw new Error(data.msg);
        }

        setAppointments(data.return);

        if (data.return.length === 0) {
          toast.current?.show({
            severity: "info",
            summary: "Informazione",
            detail: "Non ci sono appuntamenti.",
            life: 3000,
          });
        }
      } catch (error) {
        if (error instanceof Error) {
          console.error("Fetch error:", error.message);
          toast.current?.show({
            severity: "error",
            summary: "Errore",
            detail: `Si è verificato un errore: ${error.message}`,
            life: 3000,
          });
        } else {
          console.error("Unexpected error:", error);
          toast.current?.show({
            severity: "error",
            summary: "Errore",
            detail: "Si è verificato un errore sconosciuto.",
            life: 3000,
          });
        }
      }
    }
  };
  useEffect(() => {
    fetchAppointments();
  }, [isLoggedIn]);
  useEffect(() => {
    const fetchGlobalResources = async () => {
      try {
        const response = await fetch(url_getResources, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          const transformedData = data.return.map((resource: any) => ({
            label: `${resource.name} ${resource.surname}`,
            value: resource.id,
          }));
          setMedici(transformedData);
        }
      } catch (error) {
        return;
      }
    };
    fetchGlobalResources();
  }, []);
  useEffect(() => {
    const fetchResources = async () => {
      if (selectPrestazione) {
        try {
          const response = await fetch(`${url_getResources}?activity_lid=${selectPrestazione.id}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();

          const transformedData = data.return.map((resource: any) => ({
            label: `${resource.name} ${resource.surname}`,
            value: resource.id,
          }));

          setMedici(transformedData);
        } catch (error) {
          if (error instanceof Error) {
            console.error("Fetch error:", error.message);
            toast.current?.show({
              severity: "error",
              summary: "Errore",
              detail: `Si è verificato un errore: ${error.message}`,
              life: 3000,
            });
          } else {
            console.error("Unexpected error:", error);
            toast.current?.show({
              severity: "error",
              summary: "Errore",
              detail: "Si è verificato un errore sconosciuto.",
              life: 3000,
            });
          }
        }
      }
    };

    fetchResources();
  }, [selectPrestazione, token]);

  return (
    <div className="appuntamenti-div">
      <Toast ref={toast} />
      <h2>I Miei Appuntamenti</h2>
      {!isLoggedIn ? (
        <p>Per favore, effettua il login per vedere i tuoi appuntamenti.</p>
      ) : appointments.length === 0 ? (
        <p>Non hai appuntamenti prenotati.</p>
      ) : (
        <div className="p-grid">
          {appointments.map(appointment => {
            // const formatDateToDDMMYYYY = (dateString: string | undefined) => {
            //   if (!dateString) {
            //     return "Data non disponibile";
            //   }
            //   const [year, month, day] = dateString.split("/");
            //   return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
            // };

            // const formattedDate = formatDateToDDMMYYYY(appointment.appointmentDate);

            const medico = medici.find(m => m.value === appointment.resourceId)?.label;

            return (
              <div key={appointment.id} className="p-col-12 p-md-6 p-lg-4">
                <Card title={cardTitle(appointment.activityId)} className="app-card">
                  <div className="singolo-appuntamento-div">
                    <i className="pi pi-clock"></i>{" "}
                    <p>
                      {appointment.appointmentDate} - {appointment.startTime}
                    </p>
                  </div>
                  <div className="singolo-appuntamento-div">
                    <i className="pi pi-user"></i>
                    <p>Dott. {medico}</p>
                  </div>
                  <div className="singolo-appuntamento-div">
                    <i className="pi pi-euro"></i>
                    <p>{appointment.price}</p>
                  </div>
                  <div className="singolo-appuntamento-div">
                    <i
                      className="pi pi-info-circle"
                      style={{ cursor: "pointer" }}
                      onClick={() => openPreparationDialog(appointment.activityId)}
                    ></i>
                  </div>
                  <div className="singolo-appuntamento-btn-div">
                    <div className="card flex flex-wrap justify-content-center gap-3">
                      <Button
                        className="appuntamenti-btn"
                        label="Gestisci"
                        icon="pi pi-cog"
                        onClick={() => openDialog(appointment)}
                      />
                      <Button
                        className="elimina-btn appuntamenti-btn"
                        label="Elimina"
                        icon="pi pi-trash"
                        onClick={() => openDeleteDialog(appointment)}
                      />
                    </div>
                  </div>
                </Card>
              </div>
            );
          })}
        </div>
      )}
      <Dialog
        header="Elimina"
        footer={footerContent}
        visible={deleteVisible}
        className="responsive-dialog"
        onHide={() => setDeleteVisible(false)}
      >
        <p className="m-0">Sei sicuro di voler eliminare l'appuntamento? L'azione è irreversibile.</p>
      </Dialog>
      <Dialog header="Gestisci Appuntamento" visible={dialogVisible} className="responsive-dialog" onHide={closeDialog}>
        <Dialog
          header="Informazioni di Preparazione"
          visible={preparationDialogVisible}
          className="responsive-dialog"
          onHide={() => setPreparationDialogVisible(false)}
        >
          <p>{preparationText}</p>
        </Dialog>

        {selectedAppointment && (
          <>
            <div className="p-col-12 p-md-6 p-lg-4 ">
              <p>
                <strong>Esame:</strong> {selectPrestazione?.name}
              </p>
            </div>
            <div className="p-col-12 p-md-6 p-lg-4 seleziona-medico">
              <Dropdown
                value={selectMedico}
                onChange={(e: DropdownChangeEvent) => setSelectMedico(e.value)}
                options={medici}
                optionLabel="label"
                filter
                filterBy="label"
                filterMatchMode="contains"
                placeholder="Seleziona un medico"
                className="w-full dropdown"
                required
              />
            </div>
            <div className="p-col-12 p-md-6 p-lg-4 mb-2">
              <label htmlFor="startTime" className="font-bold block mb-2">
                A partire dalle ore:
              </label>
              <Dropdown
                value={startTime}
                options={timeOptions}
                onChange={e => setStartTime(e.value)}
                placeholder="opzionale"
                className="w-full dropdown"
              />
            </div>
            <div className="p-col-12 p-md-6 p-lg-4 mb-2">
              <label htmlFor="endTime" className="font-bold block mb-2">
                Entro le ore:
              </label>
              <Dropdown
                value={endTime}
                options={timeOptions}
                onChange={e => setEndTime(e.value)}
                placeholder="opzionale"
                className="w-full dropdown"
              />
            </div>
            <div className="flex-auto">
              <label htmlFor="buttondisplay" className="font-bold block mb-2">
                A partire dal:
              </label>
              <Calendar
                id="buttondisplay"
                locale="it"
                className="dropdown"
                value={date}
                onChange={e => setNewDate(e.value as Date)}
                showIcon
                dateFormat="dd/mm/yy"
                minDate={today}
                placeholder="opzionale"
              />
            </div>
            <div className="card flex flex-wrap justify-content-center gap-3">
              <Button className="submit-btn" label="INVIA" icon="pi pi-check" onClick={handleSubmit} />
            </div>
          </>
        )}
        {availabilities.map(availability => {
          return (
            <>
              <div key={availability.id} className="p-col-12 p-md-6 p-lg-4">
                <div className="card">
                  <h3>{selectPrestazione?.name}</h3>
                  <p>
                    <i className="pi pi-calendar"></i> {availability.date} - {availability.startTime}
                  </p>
                  <p>
                    <i className="pi pi-user"></i> Dott. {medici.find(m => m.value === availability.resourceId)?.label}
                  </p>
                  <p>
                    <i className="pi pi-euro"></i>
                    {availability.price}
                  </p>
                  <Button
                    label="Prenota"
                    className="p-button"
                    style={{ borderRadius: "10px" }}
                    onClick={() => openConfirmationDialog(availability)}
                  />
                </div>
              </div>
            </>
          );
        })}

        <Dialog
          header="Conferma appuntamento"
          visible={confirmationDialogVisible}
          className="responsive-dialog"
          footer={
            <div>
              <Button
                label="Annulla"
                style={{ borderRadius: "10px" }}
                icon="pi pi-times"
                onClick={() => setConfirmationDialogVisible(false)}
              />
              <Button
                label="Conferma"
                style={{ borderRadius: "10px" }}
                icon="pi pi-check"
                onClick={confirmAppointment}
              />
            </div>
          }
          onHide={() => setConfirmationDialogVisible(false)}
        >
          <div>
            <h3>{selectPrestazione?.name}</h3>
            <p>
              <i className="pi pi-calendar"></i> {selectedAppointment?.date} - {selectedAppointment?.startTime}
            </p>
            <p>
              <i className="pi pi-user"></i> Dott.{" "}
              {medici.find(m => m.value === selectedAppointment?.resourceId)?.label}
            </p>
            <p>
              <i className="pi pi-euro"></i> {selectedAppointment?.price}
            </p>
          </div>
        </Dialog>
      </Dialog>
    </div>
  );
};

export default Appuntamenti;
